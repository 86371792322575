
<template>
  <div >
    <div class="vx-row">
        <div class="vx-col lg:w-2/3 w-full">
            <vs-alert
                color="success"
                class="mb-3"
                title="Choose the order you want to send us message!"
                closable
                close-icon="cancel"
                :active.sync="isShowAlert"
                >
                <span>You can chat directly with our Customer Service Executive via the chatbot poping up at the lower right corner of this page</span>
            </vs-alert>
            <vs-table ref="table" pagination :max-items="itemsPerPage" :data="orders">
            <template slot="thead">
                <vs-th sort-key="id">Order ID</vs-th>
                <vs-th sort-key="package_name">Package</vs-th>
                <vs-th sort-key="created_at">Date</vs-th>
            </template>

            <template slot-scope="{data}">
                <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                    <p class="package-name font-bold truncate" @click="navigateToRoute(tr.id)">#DKO{{ tr.id }}</p>
                    </vs-td>

                    <vs-td>
                        <div @click="navigateToRoute(tr.id)">
                            <p class="mb-1">{{tr.package_name}}</p>
                            <p class="product-category">Total: {{tr.currency_symbol}}{{ tr.paid_amount | money_format }}</p>
                        </div>
                    </vs-td>

                    <vs-td>
                    <p @click="navigateToRoute(tr.id)" class="package-name font-medium truncate" style="line-height:1;">
                        <span class="block">{{ tr.created_at | date_time }}</span>
                        <span style="color: #aaa; font-size: 12px;">{{ tr.created_at | date_time(true) }} </span>
                    </p>
                    </vs-td>
                </vs-tr>
                </tbody>
            </template>
            </vs-table>
        </div>
    </div>
  </div>
</template>

<script>
// Cell Renderer
export default {
  name:"chat-redirect",
  data() {
    return {
      isShowAlert: true,
      itemsPerPage: 10,
      isMounted: false
    }
  },
  watch: {
    "$route.query":"refreshQueryStatus"
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    orders() {
      return this.$store.state.orderList.orders
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.orders.length
    }
  },
  methods: {
    navigateToRoute(orderId){
      this.$router.push(`/orders/${orderId}?tab=3`).catch(() => {})
    },
    refreshQueryStatus(){
        if(this.$route.query.status){
            this.defaultStatus = this.$route.query.status
        }
    }
  },
  mounted() {
    this.isMounted = true;
    this.refreshQueryStatus()
  },
  created() {
    if(!this.$store.getters['orderList/totalOrders']){
      this.$store.dispatch("orderList/fetchOrders")
    }
  }
}

</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;


      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 10px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
            &.img-container {
              // width: 1rem;
              // background: #fff;

              span {
                display: flex;
                justify-content: flex-start;
              }

              .product-img {
                height: 60px;
              }
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }



    ::-webkit-scrollbar {
     width: 10px;
     margin-top:3rem;
     height: 10px;
    }

    ::-webkit-scrollbar-thumb {
     background: #22292f;
     border-radius: 20px;
    }

    ::-webkit-scrollbar-track {
     background: #f8f8f8;
     border-radius: 20px;
    }

    .package-name {
      max-width: 23rem;
    }
  }
}
</style>
